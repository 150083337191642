import React from "react";

function TeamCard(props){
    return(
        <div className="w-full rounded-lg px-4 py-6 bg-gradient-to-b from-white via-white to-gray-100">
            <h1 className="text-center text-xl font-semibold text-gray-800">{props.name}</h1>
            <p className="mt-6 text-center"><span className="px-4 py-1 text-gray-600 bg-white rounded-full shadow-md">{props.title}</span></p>
            <p className="mt-6 text-center text-blue-custome">{props.number}</p>
            <p className="mt-2 text-center text-blue-custome">{props.email}</p>
        </div>
    );
}
export default TeamCard;