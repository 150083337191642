import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import Wave from 'react-wavify';
import TeamCard from "../components/team";


function Team(){
        return(
        <Layout>
            <Helmet>
                <title>Om oss | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Crediflow på ett enkelt och effektivt sätt hanterar både dina utgående och inkommande fakturor oavsett format." />
                <meta name="keywords" content="Om Crediflow | Vilka är Crediflow" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Vi är Crediflow" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.crediflow.se/om-oss" />
                <link rel="cannonical" href="http://www.crediflow.se/om-oss" />
            </Helmet>


            <section className="bg-gray-100 pt-4">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="bg-team-banner bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden">
                        <div className="w-full h-full" style={{background: "rgba(29, 95, 182, 0.05)"}}>
                            <h1 className="pt-40 pb-32  text-4xl lg:text-5xl font-bold text-white text-center">Crediflow</h1>
                            <Wave 
                                className="-mb-2"
                                fill='#F3F4F6'
                                paused={false}
                                options={{
                                height: 20,
                                amplitude: 50,
                                speed: 0.15,
                                points: 3
                                }}
                            />  
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-12 ">
                          <TeamCard 
                            name="Ann-Kristine Pettersson"
                            title="Front-office växel"
                            number="08-578 604 00"
                        />
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Sälj</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Anders Persson"
                            title="Partnersäljare indata"
                            number="08-578 604 05"
                        />
                        <TeamCard 
                            name="Christian Krutzfeldt"
                            title="Partnersäljare Utdata"
                            number="042-125 453"
                        />
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Support Stockholm</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Nicklas Nylén"
                            title="Support Stockholm"
                        />
                        <TeamCard 
                            name="Kjell Bergendahl"
                            title="Support 2-line Stockholm"
                        />
                        <TeamCard 
                            name="Adam Fins"
                            title="Support Stockholm"
                        />
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Support Helsingborg</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Felix Oxing"
                            title="Support Helsingborg"
                        />
                        <TeamCard 
                            name="Jonas Schrewelius"
                            title="Support Helsingborg"
                        />
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Projekt</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Anders Staaf"
                            title="Projektledare"
                            number="08-578 604 02"
                        />
                        <TeamCard 
                            name="Mari Dauner"
                            title="Produktägare CS eVoyce"
                            number="08-578 604 24"
                        />
                        <TeamCard 
                            name="Pia Sandhagen"
                            title="Projektledare"
                            number="08-578 604 48"
                        />
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Utveckling Stockholm</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Daniel S</h1>
                        </div>

                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Stefan H</h1>
                        </div>

                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Gustav E</h1>
                        </div>

                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Erik W</h1>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Utvecklare utomlands</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Sigitas L</h1>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Utvecklare Helsingborg</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Joakim P</h1>
                        </div>
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">John T</h1>
                        </div>
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">David H</h1>
                        </div>
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Elinore E</h1>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Ekonomi</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Peter Larsson "
                            title="CFO"
                            number="072-560 48 88"
                        />
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Ledning Sverige</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Niklas Horn Lundberg"
                            title="VD"
                        />
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 pt-12 pb-32">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Operativ Chef Syd</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Georgios Vasiliagos"
                            title="Operativ chef Syd"
                            number="042-127 290"
                        />
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Team;